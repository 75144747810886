import React from 'react'

const Rodape = props => {
    return(
        <footer className='container-fluid text-center'>
        <a href='#myPage' title='To Top'>
          <span className='glyphicon glyphicon-chevron-up'></span>
        </a>
        <p>Todos os direitos reservados a INTERATSIA <a href='https://www.interatisa.com.br' title='Visit w3schools'></a></p>
        </footer>



    )
}


export default Rodape